import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Stack, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PATH_AUTH } from '../../../routes/paths';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import LoginQr from './LoginQr';
import useAuthStore from '../../../store/auth/auth.store';
import { deviceInfo } from '../../../utils/deviceInfo';
import useLocales from '../../../hooks/useLocales';

type FormValuesProps = {
  username: string;
  password: string;
};

export default function LoginForm() {
  const { translate } = useLocales();
  const [ showPassword, setShowPassword ] = useState(false);

  const {
    login,
    meLoading,
  } = useAuthStore(state => ({
    login: state.login,
    meLoading: state.meLoading,
  }));

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required(translate('loginRequired')),
    password: Yup.string().required(translate('passwordRequired')),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    await login({
      ...data,
      username: data.username
        .replace(/[^a-z0-9]/gi, '')
        .toUpperCase()
    });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField
          name="username"
          label={translate('login')}
          inputProps={{
            maxLength: 9
          }}
          helperText={translate('loginHint')}
        />

        <RHFTextField
          name="password"
          label={translate('password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ my: 3 }}
      >
        <Link
          component={RouterLink}
          variant="subtitle2"
          to={PATH_AUTH.resetPassword}
        >
          {translate('forgotPassword')}
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting || meLoading}
      >
        {translate('signIn')}
      </LoadingButton>

      {/*{*/}
      {/*  deviceInfo.isMobile*/}
      {/*    ? undefined*/}
      {/*    : <LoginQr sx={{ mt: 4 }} />*/}
      {/*}*/}

      <Typography
        variant="body2"
        align="center"
        sx={{ mt: 3 }}
      >
        {translate('dontHaveAccount')}&nbsp;

        <Link
          variant="subtitle2"
          component={RouterLink}
          to={PATH_AUTH.register}
        >
          {translate('goRegister')}
        </Link>
      </Typography>
    </FormProvider>
  );
}
