import useAuthStore from '../store/auth/auth.store';
import { useLayoutEffect } from 'react';
import Cookies from 'js-cookie';
import { useUpdate } from './useUpdate';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH } from 'src/routes/paths';

export const useAuthInit = () => {
  const navigate = useNavigate();

  const {
    loadMe,
    setIsAuth,
    forceLogout,
    setForceLogout,
  } = useAuthStore(state => ({
    loadMe: state.loadMe,
    setIsAuth: state.setIsAuth,
    forceLogout: state.forceLogout,
    setForceLogout: state.setForceLogout,
  }));

  useLayoutEffect(() => {
    if (Cookies.get('is_auth')) {
      setIsAuth(true);
      loadMe();
    }
  }, []);

  useUpdate(() => {
    if (forceLogout) {
      setForceLogout(false);
      navigate(PATH_AUTH.login);
    }
  }, [forceLogout]);
};