import { useEffect, useMemo, useRef, useState } from 'react';

export const useIntervalPercent = (ticks: number = 60) => {
  const [intervalPercent, setIntervalPercent] = useState<number>(0);
  const tickInPercent = useMemo(() => 100 / ticks, [ticks]);
  const intervalRef = useRef<NodeJS.Timer>();

  const stopIntervalPercent = () => {
    intervalRef.current && clearInterval(intervalRef.current);
    setIntervalPercent(0);
  };

  const startIntervalPercent = (timeoutInSeconds: number) => {
    let intervalCount = ticks;

    stopIntervalPercent();

    intervalRef.current = setInterval(() => {
      intervalCount--;

      if (intervalCount) {
        setIntervalPercent(prevPercent => prevPercent + tickInPercent);
      } else {
        setIntervalPercent(100);
        stopIntervalPercent();
      }
    }, timeoutInSeconds * 1000 / ticks);
  };

  useEffect(() => stopIntervalPercent, []);

  return {
    intervalPercent,
    startIntervalPercent,
    stopIntervalPercent
  };
};