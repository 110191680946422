import { Alert, Stack, Typography } from '@mui/material';
import FaceIdWebcam from './FaceIdWebcam';
import useAuthStore from '../../../store/auth/auth.store';
import useLocales from '../../../hooks/useLocales';
import useTabResponsive from 'src/hooks/useTabResponsive';
import useResponsive from 'src/hooks/useResponsive';

const FaceIdForm = () => {
  const { translate } = useLocales();
  const isTab = useTabResponsive();
  const isCustomMobile = useResponsive('down', 600);

  const {
    verificationText
  } = useAuthStore(state => ({
    verificationText: state.verificationText
  }));

  return (
    <Stack alignItems="center">
      <Typography
        variant="h4"
        align={isTab ? 'center' : 'left'}
      >
        {translate('passAdditionalCheck')}
      </Typography>

      <Typography
        color="text.secondary"
        sx={{ mt: 1 }}
      >
        {translate('allowAccessToCamera')}
      </Typography>

      <FaceIdWebcam sx={{ mt: 4 }} />

      {
        verificationText
          ? (
            <Alert
              severity="warning"
              sx={{
                mt: 4,
                ...(isCustomMobile ? {
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  zIndex: 1102,
                  width: '100%',
                  borderRadius: 0,
                  mt: 0,
                } : {})
              }}
            >
              <Typography variant="body1">
                {verificationText}
              </Typography>
            </Alert>
          )
          : undefined
      }
    </Stack>
  );
};

export default FaceIdForm;