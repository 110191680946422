import { DeviceInfo } from '../@types/deviceInfo';
import { ClientJS } from 'clientjs';

export const device = new ClientJS();

export const getAndSaveFingerprint = () => {
  let fingerprint = localStorage.getItem('fingerprint');

  if (!fingerprint) {
    fingerprint = device.getFingerprint().toString();

    localStorage.setItem('fingerprint', fingerprint);
  }

  return fingerprint;
};

export const deviceInfo: DeviceInfo = {
  browser: device.getBrowser(),
  browserVersion: device.getBrowserVersion(),
  id: getAndSaveFingerprint(),
  os: device.getOS(),
  osVersion: device.getOSVersion(),
  isMobile: device.isMobile(),
  isFirefox: device.isFirefox(),
};