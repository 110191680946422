import { LinkType, PageType } from '../@types/page';
import { BookSelectionType, RouteByBookSelectionType } from '../store/books-crud/types';
import { WithAnyProps } from '../@types/common';

export function getPathByPageOrLinkType(pageOrLink: PageType | LinkType): string {
  return ((pageOrLink as PageType).type
    ? getPathByPageType(pageOrLink as PageType)
    : (pageOrLink as LinkType).href) || '';
}

export function getPathByPageType(pageType: PageType): string {
  return pageType.type
    && routeHandlersByPageTypes.hasOwnProperty(pageType.type)
    ? routeHandlersByPageTypes[pageType.type]!(pageType)
    : routeHandlersByPageTypes[BookSelectionType.default]!(pageType);
}

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export function objectToQuery(queryObject: WithAnyProps): string {
  let query: string = '';

  for (const queryKey in queryObject)
    query += (query ? '&' : '?') + `${queryKey}=${queryObject[queryKey]}`;

  return query;
}

export function queryToObject(query: string): WithAnyProps {
  const queryObject: WithAnyProps = {};

  query
    .split('&')
    .forEach(queryItem => {
      let [ key, value ] = queryItem.split('=');

      if (key.slice(0, 1) === '?')
        key = key.slice(1);

      queryObject[key] = value;
    });

  return queryObject;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
export const DOCUMENT_BASE_PATH = '/assets/docs';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  bookDetail: (pageType: Partial<PageType>) => `/book/${pageType.id}`,
  userProfile: '/account',
  userInvoices: '/statement',
  userInvoiceItem: (pageType: PageType) => `/statement/${pageType.id}`,
  bookDetailPage: (pageType: PageType) => '/book/list/' +
    pageType.page?.endpoint +
    objectToQuery(pageType.page?.query || {}),
  bookReader: (pageType: PageType) => `/book/${pageType.id}/read`,
  bookDetailSearch: (pageType: PageType) => '/book/search' +
    objectToQuery(pageType.page?.query || {}),
};

const routeHandlersByPageTypes: RouteByBookSelectionType = {
  [BookSelectionType.book]: PATH_PAGE.bookDetail,
  [BookSelectionType.main]: () => '/',
  [BookSelectionType.book_read]: PATH_PAGE.bookReader,
  [BookSelectionType.invoice_details]: PATH_PAGE.userInvoiceItem,
  [BookSelectionType.book_list]: PATH_PAGE.bookDetailPage,
  [BookSelectionType.search]: PATH_PAGE.bookDetailSearch,
  [BookSelectionType.default]: PATH_PAGE.bookDetailSearch,
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string | number) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const STATIC_LINKS = {
  privacyPolicy: (lang: string) =>
    `${DOCUMENT_BASE_PATH}/confidentiality-agreement-${lang}.pdf`,
  correspondence: (lang: string) =>
    `${DOCUMENT_BASE_PATH}/correspondence-${lang}.pdf`,
  correspondenceWinter: (lang: string) =>
    `${DOCUMENT_BASE_PATH}/correspondence-${lang}-winter.pdf`,
  fullTime: (lang: string) =>
    `${DOCUMENT_BASE_PATH}/full-time-${lang}.pdf`,
  fullTimeWinter: (lang: string) =>
    `${DOCUMENT_BASE_PATH}/full-time-${lang}-winter.pdf`,
  fullTimeInternational: (lang: string) =>
    `${DOCUMENT_BASE_PATH}/full-time-international-${lang}.pdf`,
  fullTimeInternationalWinter: (lang: string) =>
    `${DOCUMENT_BASE_PATH}/full-time-international-${lang}-winter.pdf`,
  email: 'mailto:info@stars.university',
  location: 'https://goo.gl/maps/CX9iVUUxQzJ1Vjff7',
  facebook: 'https://fb.com/starsinternationaluniversity',
  instagram: 'https://www.instagram.com/stars.university.uz/',
  tiktok: 'https://tiktok.com/@stars_university',
  youtube: 'https://www.youtube.com/channel/UC8sS6Q1U2P0SWugztBJdduA',
  telegram: 'https://t.me/starsuniversity',
};